<template>
  <el-dialog
    v-loading="loading"
    v-elDragDialog
    width="700px"
    :title="isCreated ? $t('deviceModal.bindingDevice') : $t('deviceModal.editDevice')"
    :close-on-click-modal="false"
    :visible.sync="show"
    class="add-device"
  >
    <el-form :model="form" :rules="rules" size="small" ref="form" label-width="100px">
      <div class="flex">
        <el-form-item :label="$t('deviceList.customer')">
          <el-input readonly v-model.trim="spName" />
        </el-form-item>
        <el-form-item label-width="10px" prop="groupId">
          <!-- <el-select
            filterable
            v-model="form.groupId"
            :placeholder="$t('deviceModal.selectGrouping')"
          >
            <el-option
              v-for="item in groupList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select> -->
          <GroupCascader :options="treeData" :value.sync="form.groupId" />
        </el-form-item>
      </div>

      <el-form-item :label="$t('deviceList.sn')" :prop="isCreated ? 'sn' : ''">
        <el-input
          :disabled="!isCreated"
          v-model.trim="form.sn"
          :placeholder="$t('deviceModal.pleaseEnterSNNumber')"
        />
      </el-form-item>
      <el-form-item :label="$t('deviceList.store')">
        <el-input v-model.trim="form.store" :placeholder="$t('customerHome.enterStore')" />
      </el-form-item>
      <div class="flex">
        <el-form-item :label="$t('deviceList.address')">
          <el-select value="china" v-if="$i18n.locale == 'zh'">
            <el-option label="中国" value="china" />
          </el-select>
          <el-select filterable clearable v-model="form.countryId" v-else>
            <el-option
              v-for="(item, index) in countryLists"
              :key="index"
              :label="$i18n.locale == 'en' ? item.en : item.ruRU"
              :value="item.code"
            />
          </el-select>
        </el-form-item>
        <el-form-item label-width="10px" v-if="$i18n.locale == 'zh'">
          <el-select @change="provinceChange" v-model="form.provinceId" placeholder="选择省份">
            <el-option
              v-for="item in provinces"
              :key="item.value"
              :label="item.label"
              :value="item.label"
            />
          </el-select>
        </el-form-item>
        <el-form-item v-if="haveCity && $i18n.locale == 'zh'" label-width="10px">
          <el-select @change="cityChange" v-model="form.cityId" placeholder="选择城市">
            <el-option
              v-for="item in citys"
              :key="item.value"
              :label="item.label"
              :value="item.label"
            />
          </el-select>
        </el-form-item>
        <el-form-item label-width="10px">
          <el-select v-model="form.areaId" placeholder="选择区域" v-if="$i18n.locale == 'zh'">
            <el-option
              v-for="item in areas"
              :key="item.value"
              :label="item.label"
              :value="item.label"
            />
          </el-select>
        </el-form-item>
        <el-tooltip
          class="item"
          effect="light"
          :content="$t('deviceList.getLocation')"
          placement="right"
        >
          <i
            v-if="$i18n.locale == 'zh'"
            @click="getLocation"
            style="font-size: 30px;cursor: pointer;width: 30px;height: 30px;"
            :class="getLocationLoading ? 'el-icon-loading' : 'el-icon-location-outline'"
          ></i>
        </el-tooltip>
      </div>

      <el-form-item :label="$t('deviceList.detailedAddress')">
        <el-input
          v-model.trim="form.address"
          :placeholder="$t('deviceModal.pleaseEnterDetailedAddress')"
        />
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="submitForm(form)">{{ $t('save') }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'
import GroupCascader from '@/pages/index/modules/components/GroupCascader'
import { position } from '@/lib/position.js'
import { countryList } from '@/lib/countryList.js'
export default {
  mixins: [dialogCommonParams],
  components: { GroupCascader },
  props: {
    spName: {
      type: String,
      default: ''
    },
    groupList: {
      type: Array,
      default: () => []
    },
    modelList: {
      type: Array,
      default: () => []
    },
    treeData: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      haveCity: true,
      loading: false,

      provinces: [],

      citys: [],

      cityPosition: [],

      areas: [],

      form: {
        countryId: 1,
        spName: '',
        groupId: '',
        sn: '',
        provinceId: '',
        cityId: '',
        areaId: '',
        address: '',
        store: ''
      },
      rules: {
        spName: [{ required: true, message: this.$t('serviceManagement.enterCustomerName') }],
        groupId: [{ required: true, message: this.$t('deviceModal.selectGrouping') }],
        sn: [{ required: true, message: this.$t('deviceModal.pleaseEnterSNNumber') }]
      },
      getLocationLoading: false
    }
  },
  // computed: {
  //   currentName() {
  //     const target = this.serviceList.find(_ => _.value === +sessionStorage.getItem('spId'))

  //     return target ? target.label : sessionStorage.getItem('spId')
  //   }
  // },
  created() {
    if (this.$i18n.locale == 'zh') {
      this.form.countryId = '1'
    } else {
      this.form.countryId = ''
      this.countryLists = countryList
    }
    this.provinces = position.map(item => {
      return {
        label: item.name,
        value: item.pid
      }
    })

    if (!this.isCreated) {
      const targetPid = this.currentItem.provinceId
      const targetCid = this.currentItem.cityId

      if (targetPid) {
        this.provinceChange(this.currentItem.provinceId)
      }

      if (targetCid) {
        this.cityChange(this.currentItem.cityId)
      }

      Object.keys(this.form).forEach(key => {
        if (['provinceId', 'cityId', 'areaId'].includes(key)) {
          this.form[key] = this.currentItem[key]
        } else if (key === 'groupId' && this.currentItem['groups']) {
          this.form.groupId =
            (this.currentItem['groups'][0] && this.currentItem['groups'][0].id) || ''
        } else {
          this.form[key] = this.currentItem[key]
        }
      })
    }
  },
  methods: {
    async getLocation() {
      try {
        if (!this.form.sn) {
          this.$message.warning(this.$t('deviceModal.pleaseEnterSNNumber'))
          return
        }
        if (this.getLocationLoading) return
        this.getLocationLoading = true
        let { result = '' } = await this.$api.deviceManagement.getLocation(this.form.sn)
        if (result) {
          // 利用正则提取省、市、区
          let reg =
            '(?<country>[^国]+国)?(?<province>[^省]+自治区|.*?省|.*?行政区|.*?市)?(?<city>[^市]+自治州|.*?地区|.*?行政单位|.+盟|市辖区|.*?市|.*?县)?(?<county>[^县]+县|.+区|.+市|.+旗|.+海域|.+岛)'
          let addres = result.split(',')[0].match(reg)
          /*
            str : '中国广东省深圳市南山区科技路1号,在桑达科技大厦附近'.split(',')
            addres: ['中国广东省深圳市南山区', '中国', '广东省', '深圳市', '南山区', index: 0, input: '中国广东省深圳市南山区科技路1号']

          */
          if (addres.length > 0) {
            if (addres.length > 0) {
              if (addres[2]) {
                this.form.provinceId = addres[2]
                this.provinceChange(addres[2])
              }
              if (addres[3]) {
                this.form.cityId = addres[3]
                this.cityChange(addres[3])
              }
              this.form.areaId = addres[4] ? addres[4] : ''
              this.form.address = result.split(',')[1] || ''
            }
          }
        } else {
          this.$message.warning(this.$t('deviceList.noLocation'))
        }
        this.getLocationLoading = false
      } catch (e) {
        console.log(e)
        this.getLocationLoading = false
      }
    },
    provinceChange(name) {
      this.form.cityId = ''
      this.form.areaId = ''

      const targetPid = position.find(item => item.name === name)

      if (
        ['北京市', '上海市', '天津市', '重庆市', '香港特别行政区', '澳门特别行政区'].includes(name)
      ) {
        this.haveCity = false

        this.form.cityId = name

        this.areas = position
          .find(item => item.name === name)
          .cityList[0].areaList.map(_ => {
            return {
              label: _.name,
              value: _.did
            }
          })

        return
      } else {
        this.haveCity = true
      }

      if (targetPid) {
        this.cityPosition = position.find(item => item.name === name).cityList
      }

      if (this.cityPosition) {
        this.citys = this.cityPosition.map(_ => {
          return {
            label: _.name,
            value: _.cid
          }
        })
      }
    },
    cityChange(name) {
      this.form.areaId = ''
      const target = this.cityPosition.find(item => item.name === name)

      if (target) {
        this.areas = target.areaList.map(_ => {
          return {
            label: _.name,
            value: _.did
          }
        })
      }
    },
    submitForm(data) {
      this.$refs.form.validate(async valid => {
        if (valid) {
          if (!this.isCreated) {
            data.sn = this.currentItem.sn
          }
          this.loading = true
          try {
            this.isCreated
              ? await this.$api.deviceManagement.deviceAdd(data)
              : await this.$api.deviceManagement.deviceUpdate(data)
            this.$message.success(this.isCreated ? this.$t('newSuccess') : this.$t('editSuccess'))
            this.$emit('getData')
            this.show = false
          } catch (e) {
            console.log(e)
          } finally {
            this.loading = false
          }
        }
      })
    }
  }
}
</script>
