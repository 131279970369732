<template>
  <el-dialog
    v-elDragDialog
    v-loading="loading"
    width="700px"
    :title="$t('appList.appDetails')"
    :close-on-click-modal="false"
    :visible.sync="show"
    class="add-detail"
  >
    <div class="header  viewer" v-viewer>
      <img :src="item.iconPathUrl" />
      <div style="margin-left:10px">
        <h3>{{ item.appName }}</h3>
        <p style="margin:10px 0">
          <span>{{ $utils.bytesToSize(item.appSize) }}</span>
          <span style="margin-left:20px"
            >{{ $t('deviceList.versionNo') }}：{{ item.versionName }}</span
          >
        </p>
        <p>
          <span>{{ $t('deviceList.packageName') }}: {{ item.packageName }}</span>
          <el-button size="small" type="primary" @click="downLoad"
            >{{ $t('appList.downloadPackage') }}
          </el-button>
        </p>
      </div>
    </div>
    <div class="content">
      <p>
        <span class="label">{{ $t('appList.appInfo') }}: </span>
        <span>
          {{ item.appDesc }}
        </span>
      </p>
      <p>
        <span class="label">{{ $t('appList.appScreenshot') }}: </span>
        <span class="appScreenshot  viewer" v-viewer>
          <img :src="item" v-for="item in appImagePath" :key="item" />
        </span>
      </p>
      <p>
        <span class="label">{{ $t('appList.versionDesc') }}: </span>
        <span>
          {{ item.releaseNote }}
        </span>
      </p>
    </div>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'
export default {
  mixins: [dialogCommonParams],
  components: {},
  props: {
    appInfo: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      loading: false,
      appImagePath: [],
      item: {}
    }
  },
  created() {
    this.getDetail()
  },
  methods: {
    async getDetail() {
      try {
        this.loading = true
        const res = await this.$api.appManagement.appGet({ appId: this.appInfo.appId })
        this.item = res.result
        this.appImagePath = this.item.appImagePathUrl.split(',')
      } catch (err) {
      } finally {
        this.loading = false
      }
    },
    async downLoad() {
      try {
        this.loading = true
        let formData = new FormData()
        formData.append('appId', this.appInfo.appId)

        const res = await this.$api.appManagement.getAppUrl(formData)

        window.open(res.result, '_self')
      } catch (err) {
        console.log(err)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.add-detail {
  p {
    display: flex;
  }
  .header {
    display: flex;
    margin-bottom: 10px;

    p {
      align-items: center;
    }

    img {
      width: 80px;
      height: 80px;
    }

    .el-button {
      margin-left: 10px;
    }
  }

  .content {
    padding: 20px;
    p {
      align-items: center;
      margin-bottom: 20px;
      .label {
        width: 120px;
        margin-right: 10px;
        flex-shrink: 0;
        word-break: break-word;
      }

      .appScreenshot {
        display: flex;
        img {
          width: 120px;
          height: 120px;
          margin-right: 10px;
        }
      }
    }
  }
}
</style>
