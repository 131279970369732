<template>
  <el-dialog
    v-loading="loading"
    v-elDragDialog
    width="700px"
    :title="$t('groupManagement.modifyGroup')"
    :append-to-body="true"
    :close-on-click-modal="false"
    :visible.sync="show"
    class="add-device"
  >
    <el-form :model="form" :rules="rules" size="small" ref="form" label-width="100px">
      <div class="flex">
        <el-form-item :label="$t('deviceList.customer')">
          <el-input disabled v-model.trim="form.spName" />
        </el-form-item>
        <el-form-item label-width="10px" prop="groupId">
          <!-- <el-select v-model="form.groupId" :placeholder="$t('deviceModal.selectGrouping')">
            <el-option
              v-for="item in groupList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select> -->
          <GroupCascader :value.sync="form.groupId" />
        </el-form-item>
      </div>

      <el-form-item :label="$t('deviceList.sn')">
        <el-input disabled v-model.trim="form.sn" />
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="submitForm(form)">{{ $t('save') }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'
import GroupCascader from '@/pages/index/modules/components/GroupCascader'
import { position } from '@/lib/position.js'
export default {
  mixins: [dialogCommonParams],
  components: { GroupCascader },
  props: ['groupList', 'currentRow'],
  data() {
    return {
      loading: false,
      form: {
        spName: '',
        groupId: '',
        sn: ''
      },
      rules: {
        groupId: [{ required: true, message: this.$t('customerHome.pleaseSelectGroup') }]
      }
    }
  },

  created() {
    this.form.sn = this.currentRow.sn
    this.form.spName = this.currentRow.spName
    this.form.groupId = this.currentRow.groups[0].id
  },
  methods: {
    submitForm(data) {
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.loading = true
          try {
            await this.$api.deviceManagement.deviceUpdate(data)
            this.$message.success(this.$t('groupManagement.modifySuccess'))
            this.$emit('getData')
            this.show = false
          } catch (e) {
            console.log(e)
          } finally {
            this.loading = false
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped></style>
