<template>
  <el-dialog
    :close-on-click-modal="false"
    v-loading="loading"
    v-elDragDialog
    :title="$t('publishApp.selectApps')"
    :visible.sync="show"
    width="820px"
  >
    <div class="header">
      <div class="left">
        <el-input
          v-model.trim="params.appName"
          @keyup.enter.native="replaceData"
          :placeholder="$t('appList.enterAppName')"
        />
        <el-button type="primary" plain @click="replaceData">{{ $t('search') }}</el-button>
      </div>
    </div>

    <div class="content" v-scroll="loadMore">
      <div class="row" v-for="item in list" :key="item.id">
        <div class="info">
          <div class="viewer" v-viewer>
            <img :src="item.iconPathUrl" />
          </div>
          <div class="name">{{ item.appName }}</div>
          <div class="info-b">
            <span>{{ item.appSize }}</span>
            <span>{{ $t('deviceList.versionNo') }} V{{ item.versionName }}</span>
          </div>
        </div>
        <div class="versions">
          <el-checkbox-group v-model="item.checks">
            <el-checkbox v-for="subItem in item.children" :key="subItem.id" :label="subItem.id">
              <div class="version">
                <span>V{{ subItem.versionName }}</span>
                <span>{{ subItem.appSize }}</span>
              </div>
            </el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="submit">{{ $t('confirm') }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'
import watchParamsGetData from '@/mixins/watchParamsGetData'
export default {
  mixins: [dialogCommonParams, watchParamsGetData],
  components: {},
  props: {
    selectApp: {
      type: Array,
      default: () => []
    },
    currentSn: {
      type: String
    }
  },
  data() {
    return {
      loading: false,
      params: {
        pageSize: 50,
        currentPage: 1,
        appName: ''
      },
      appSendData: {
        appInfos: [],
        publishObjectIds: '',
        publishWay: 2
      },
      list: [],
      noLoad: false
    }
  },
  async created() {
    await this.getData()
    if (this.selectApp.length) {
      this.init()
    }
  },
  methods: {
    loadMore() {
      if (this.noLoad) return

      ++this.params.currentPage
      this.getData()
    },
    init() {
      this.list.forEach(item => {
        const arr = this.selectApp.filter(e => e.id === item.id)
        item.checks = arr.map(item => item.id)
      })
    },
    async getData() {
      try {
        this.loading = true
        const res = await this.$api.appManagement.appList(this.params)
        const list = (res.result && res.result.list) || []

        if (list.length < this.params.pageSize) {
          this.noLoad = true
        } else {
          this.noLoad = false
        }

        const promises = list.map(item => {
          const { packageName } = item
          return this.$api.appManagement.getAppsByPackageName({ packageName })
        })
        const ress = await Promise.all(promises)
        ress.forEach((item, index) => {
          const versionList = []

          list[index].checks = []
          list[index].appSize = this.$utils.bytesToSize(list[index].appSize)

          list[index].children = (item.result || []).reduce((arr, item) => {
            if (!versionList.includes(item.versionName)) {
              versionList.push(item.versionName)
              item.appSize = this.$utils.bytesToSize(item.appSize)
              arr.push(item)
            }

            return arr
          }, [])
        })

        this.list = this.params.currentPage === 1 ? list : this.list.concat(list)
      } catch (err) {
        console.log(err)
      } finally {
        this.loading = false
      }
    },
    async submit() {
      const data = this.list.map(item => {
        return item.checks.map(id => {
          const s = item.children.find(item => item.id === id)
          s.iconPathUrl = item.iconPathUrl
          return s
        })
      })

      this.appSendData.publishObjectIds = this.currentSn
      this.appSendData.appInfos = data.flat()

      try {
        this.loading = true
        const res = await this.$api.appManagement.publishPost(this.appSendData)
        this.$message.success(this.$t('publishApp.publishSuccess'))
        this.show = false
        this.$emit('getData')
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .left {
    display: flex;
    button {
      margin-left: 10px;
    }
  }
}
.content {
  margin-top: 10px;
  height: 500px;
  overflow: auto;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  & > .row {
    & + .row {
      margin-top: 10px;
    }
  }
  .row {
    display: flex;
    img {
      width: 30px;
      height: 30px;
    }
  }
  .versions {
    display: flex;
    flex-wrap: wrap;
    .version {
      display: flex;
      flex-direction: column;
    }
    ::v-deep .el-checkbox__input {
      margin-top: 15px;
    }
  }
}
.info {
  width: 200px;
  flex-wrap: wrap;
  word-break: break-word;
  border: 1px solid #ccc;
  padding-top: 10px;
  margin-bottom: 5px;
  flex-shrink: 0;
  border-radius: 4px;
  display: flex;
  cursor: pointer;
  align-items: center;
  flex-direction: column;
  padding-bottom: 10px;
  margin-right: 10px;
  .name {
    font-size: 15px;
    font-weight: bold;
    margin: 10px 0;
    text-align: center;
  }
  .info-b {
    padding: 0 10px;
    text-align: center;
    span {
      font-size: 13px;
    }
    span + span {
      margin-left: 10px;
    }
  }
  div {
    margin-right: 10px;
  }
}
</style>
