<template>
  <el-dialog
    v-loading="loading"
    v-elDragDialog
    width="1100px"
    :title="$t('deviceList.timedSwitch')"
    :close-on-click-modal="false"
    :visible.sync="show"
  >
    <div class="wrap">
      <div class="left">
        <el-input :placeholder="$t('deviceList.filter')" v-model="filterText" />
        <el-tree
          ref="tree"
          :data="treeData"
          show-checkbox
          node-key="id"
          default-expand-all
          :expand-on-click-node="false"
          :props="defaultProps"
          :filter-node-method="filterNode"
        />
      </div>
      <div class="right">
        <el-form
          :model="form"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item :label="$t('deviceList.executionMode')" prop="executionMode">
            <el-select v-model="form.executionMode" :placeholder="$t('placeholder')">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item
            v-if="form.executionMode == 2"
            :label="$t('deviceList.executionDate')"
            prop="week"
          >
            <el-checkbox-group v-model="form.week">
              <el-checkbox v-for="item in week" :label="item.label" :key="item.value" />
            </el-checkbox-group>
          </el-form-item>
          <el-form-item
            v-if="form.executionMode == 3"
            :label="$t('deviceList.executionDate')"
            prop="week"
          >
            <el-select multiple collapse-tags v-model="form.week" :placeholder="$t('placeholder')">
              <el-option v-for="day in 31" :key="day" :label="day" :value="day" />
            </el-select>
          </el-form-item>
          <el-form-item
            v-if="form.executionMode == 4"
            :label="$t('deviceList.executionDate')"
            prop="day"
          >
            <el-date-picker v-model="form.day" type="date" :placeholder="$t('placeholder')" />
          </el-form-item>
          <el-form-item :label="$t('deviceList.executionTime')">
            <el-table
              ref="multipleTable"
              :data="tableData"
              tooltip-effect="dark"
              style="width: 100%"
            >
              <el-table-column align="center" type="selection" width="50" />

              <el-table-column
                prop="startupTime"
                :label="$t('deviceList.startupTime')"
                show-overflow-tooltip
                align="center"
              >
                <template slot-scope="{ row }"
                  ><el-time-picker
                    style="width:140px"
                    v-model="row.startupTime"
                    :picker-options="{
                      selectableRange: '00:00:00 - 23:59:59'
                    }"
                    :placeholder="$t('placeholder')"
                /></template>
              </el-table-column>

              <el-table-column
                prop="shutdownTime"
                :label="$t('deviceList.shutdownTime')"
                show-overflow-tooltip
                align="center"
              >
                <template slot-scope="{ row }">
                  <el-time-picker
                    style="width:140px"
                    v-model="row.shutdownTime"
                    :picker-options="{
                      selectableRange: '00:00:00 - 23:59:59'
                    }"
                    :placeholder="$t('placeholder')"
                  />
                </template>
              </el-table-column>
            </el-table>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="submitForm()">{{ $t('save') }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'
export default {
  mixins: [dialogCommonParams],
  props: ['groupIds'],
  components: {},

  data() {
    const rules = {
      executionMode: [{ required: true, message: this.$t('placeholder'), trigger: 'change' }],
      week: [{ required: true, message: this.$t('placeholder'), trigger: 'change' }],
      day: [{ required: true, message: this.$t('placeholder'), trigger: 'change' }]
    }

    return {
      rules,
      form: {
        week: [],
        day: ''
      },
      filterText: '',
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      treeData: [
        {
          id: 1,
          label: '一级 1',
          children: [
            {
              id: 4,
              label: '二级 1-1',
              children: [
                {
                  id: 9,
                  label: '三级 1-1-1'
                },
                {
                  id: 10,
                  label: '三级 1-1-2'
                }
              ]
            }
          ]
        },
        {
          id: 2,
          label: '一级 2',
          children: [
            {
              id: 5,
              label: '二级 2-1'
            },
            {
              id: 6,
              label: '二级 2-2'
            }
          ]
        },
        {
          id: 3,
          label: '一级 3',
          children: [
            {
              id: 7,
              label: '二级 3-1'
            },
            {
              id: 8,
              label: '二级 3-2'
            }
          ]
        }
      ],
      options: [
        {
          label: this.$t('deviceList.everyDay'),
          value: 1
        },
        {
          label: this.$t('deviceList.weekly'),
          value: 2
        },
        {
          label: this.$t('deviceList.everyMonth'),
          value: 3
        },
        {
          label: this.$t('deviceList.customize'),
          value: 4
        }
      ],
      week: [
        {
          label: this.$t('deviceList.mon'),
          value: 1
        },
        {
          label: this.$t('deviceList.tue'),
          value: 2
        },
        {
          label: this.$t('deviceList.wed'),
          value: 3
        },
        {
          label: this.$t('deviceList.thu'),
          value: 4
        },
        {
          label: this.$t('deviceList.fri'),
          value: 5
        },
        {
          label: this.$t('deviceList.sat'),
          value: 6
        },
        {
          label: this.$t('deviceList.sun'),
          value: 7
        }
      ],
      tableData: [
        {
          startupTime: '',
          shutdownTime: ''
        },
        {
          startupTime: '',
          shutdownTime: ''
        },
        {
          startupTime: '',
          shutdownTime: ''
        },
        {
          startupTime: '',
          shutdownTime: ''
        }
      ]
    }
  },
  created() {},
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val)
    }
  },
  methods: {
    filterNode(value, data) {
      if (!value) return true
      return data.label.indexOf(value) !== -1
    },
    async submitForm() {
      this.loading = true

      const params = {
        parameter1: this.light,
        type: 10
      }

      if (this.groupIds) {
        params.groupIds = this.groupIds.join(',')
      } else {
        params.deviceSn = this.currentItem.sn
      }

      try {
        const res = await this.$api.deviceManagement.publishRemoteAssistance(params)

        this.$message.success(this.$t('deviceModal.settingSuccess'))
        this.show = false
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  display: flex;
  padding: 20px;
  .left,
  .right {
    flex: 1;
    padding: 10px;
  }
}
</style>
