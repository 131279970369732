<template>
  <el-dialog
    v-loading="loading"
    v-elDragDialog
    width="500px"
    :title="$t('tips')"
    :close-on-click-modal="false"
    :visible.sync="show"
    class="ShutDownModal"
  >
    <el-form size="small" ref="form" label-width="100px">
      <el-radio-group v-model="shutdownType" @change="changeHandler">
        <el-radio v-for="item in items" :key="item.value" :label="item.label">
          {{ item.title }}
        </el-radio>
      </el-radio-group>

      <div v-if="shutdownType === 1" class="down-part-wrap">
        <el-date-picker
          v-if="count === 1"
          v-model="shutdownTime"
          type="datetime"
          :placeholder="$t('deviceModal.pleaseSelectThutdownTime')"
        />
        <el-time-picker
          v-else
          v-model="shutdownTime"
          :placeholder="$t('deviceModal.pleaseSelectThutdownTime')"
        />

        <el-select style="width:150px;margin-left:10px" v-model="count">
          <el-option
            v-for="item in lists"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </div>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="submitForm()">{{ $t('save') }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'
export default {
  mixins: [dialogCommonParams],
  props: {
    groupIds: {
      type: Array,
      default: () => []
    },
    multipleSelection: {
      type: Array,
      default: () => []
    }
  },
  components: {},
  data() {
    return {
      shutdownType: 0,
      count: 0,
      shutdownTime: '',
      items: [
        {
          title: this.$t('deviceModal.shutdownImmediately'),
          label: 0
        },
        {
          title: this.$t('deviceModal.timedShutdown'),
          label: 1
        },
        {
          title: this.$t('deviceModal.undoShutdown'),
          label: 2
        }
      ],
      lists: [
        {
          label: this.$t('deviceModal.permanent'),
          value: 0
        },
        {
          label: this.$t('deviceModal.once'),
          value: 1
        }
      ]
    }
  },
  created() {},
  methods: {
    changeHandler(val) {
      this.shutdownTime = ''
      this.count = 0
    },
    async submitForm(data) {
      if (this.shutdownType === 1 && !this.shutdownTime) {
        this.$message.warning(this.$t('deviceModal.pleaseSelectThutdownTime'))
        return
      }

      this.loading = true

      const params = {
        parameter1: this.shutdownType,
        parameter2: this.count,
        parameter3: this.$utils.formatTime(this.shutdownTime),
        type: 8
      }

      if (this.groupIds.length) {
        params.groupIds = this.groupIds.join(',')
      } else if (this.multipleSelection.length) {
        params.deviceSn = this.multipleSelection.join(',')
      } else {
        params.deviceSn = this.currentItem.sn
      }

      try {
        const res = await this.$api.deviceManagement.publishRemoteAssistance(params)

        this.$message.success(this.$t('deviceModal.settingSuccess'))
        this.show = false
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
h4 {
  text-align: center;
  margin-bottom: 20px;
}

::v-deep .el-dialog__body {
  padding-top: 10px;
}

::v-deep .el-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

::v-deep .el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 193px;
}

::v-deep .el-radio {
  display: block;
  margin-bottom: 16px;
}

.down-part-wrap {
  display: flex;
}
</style>
