<template>
  <page>
    <div slot="headerLeft">
      <el-select clearable v-model="params.type" :placeholder="$t('operateList.operationName')">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>

      <el-input
        :placeholder="$t('appList.appName')"
        v-model.trim="params.appName"
        @keyup.enter.native="replaceData"
      />
      <el-input
        :placeholder="$t('deviceList.sn')"
        v-model.trim="params.sn"
        @keyup.enter.native="replaceData"
      />
      <el-button type="primary" @click="replaceData" plain>{{ $t('query') }}</el-button>
    </div>

    <div class="table-wrap">
      <el-table :data="tableData" border v-loading="loading" height="100%">
        <el-table-column prop="operateName" :label="$t('operateList.operationName')" />
        <el-table-column prop="time" :label="$t('deviceList.operationTime')">
          <template slot-scope="{ row }">
            {{ $utils.formatTime(row.createTime) }}
          </template>
        </el-table-column>
        <el-table-column prop="publishWayStr" :label="$t('operateList.type')" />
        <el-table-column prop="sumDevice" :label="$t('operateList.associatedDevicesNumber')" />

        <el-table-column :label="$t('operateList.see')" width="130">
          <template slot-scope="{ row }">
            <div class="operateRow">
              <el-button
                v-if="btnShow && btnShow.detail"
                type="primary"
                size="small"
                @click="toDetail(row)"
                >{{ $t('details') }}</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <pagination
      slot="pagination"
      :total="total"
      :page-size.sync="params.pageSize"
      :page.sync="params.currentPage"
      @change="getData"
    />
  </page>
</template>

<script>
import watchParamsGetData from '@/mixins/watchParamsGetData'
export default {
  mixins: [watchParamsGetData],
  data() {
    return {
      options: [
        {
          label: this.$t('operateList.publishApp'),
          value: 1
        },
        {
          label: this.$t('operateList.uninstallApp'),
          value: 2
        }
      ],
      types: [
        {
          value: 1,
          label: this.$t('customerHome.byGroup')
        },
        {
          value: 2,
          label: this.$t('customerHome.bySN')
        },
        {
          value: 3,
          label: this.$t('customerHome.byDeviceModel')
        },
        {
          value: 4,
          label: this.$t('customerHome.full')
        }
      ],
      detailVisible: false,
      releaseVisible: false,
      currentItem: null,
      tableData: []
    }
  },
  created() {},
  methods: {
    async getData() {
      if (!this.params.type) {
        delete this.params.type
      }

      try {
        this.loading = true

        const res = await this.$api.appManagement.publishList(this.params)

        if (res.result) {
          this.tableData = (res.result.list || []).map(item => {
            const e = this.types.find(e => e.value === item.publishWay)
            if (e) {
              item.publishWayStr =
                e.label +
                (item.type === 2 ? this.$t('publishApp.uninstall') : this.$t('publishApp.release'))
            }
            item.operateName =
              item.type === 2
                ? this.$t('operateList.uninstallApp')
                : this.$t('operateList.publishApp')
            item.imgs = (item.appInfos || []).map(item => item.iconPathUrl)
            return item
          })
          this.total = res.result.total
        } else {
          this.tableData = []
          this.total = 0
        }
      } catch (err) {
        console.log(err)
      } finally {
        this.loading = false
      }
    },
    toDetail(row) {
      sessionStorage.setItem('currentAppDetail', JSON.stringify(row))

      this.$router.push({
        name: 'processProgressDetail',
        params: {
          id: row.id,
          type: row.type
        }
      })
    },
    async delHandler(publishId) {
      await this.$confirm(this.$t('operateList.deleteRecord'), this.$t('tips'), {
        confirmButtonText: this.$t('confirm'),
        cancelButtonText: this.$t('cancel')
      })

      try {
        this.loading = true
        await this.$api.appManagement.publishDel({ publishId })
        this.$message.success(this.$t('deleteSuccess'))
        this.getData()
      } catch (err) {
        console.log(err)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
img {
  width: 60px;
  height: 60px;
}
.table-wrap {
  .row {
    display: flex;

    flex-wrap: wrap;
    img {
      margin-right: 10px;
    }
    & > div {
      border: 1px solid #ccc;
      margin: 5px;
      padding: 5px;
      display: flex;
      align-items: center;
      flex-direction: column;
      .name {
        font-size: 15px;
        font-weight: bold;
        margin: 10px 0;
      }

      & + div {
        margin-left: 10px;
      }
    }
  }
}
</style>
