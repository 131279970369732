<template>
  <page>
    <div slot="headerLeft">
      <el-input
        :placeholder="$t('deviceList.sn')"
        v-model.trim="params.sn"
        @keyup.enter.native="replaceData"
      />
      <el-button type="primary" @click="replaceData" plain>{{ $t('query') }}</el-button>
    </div>
    <!-- <div style="margin-bottom:15px">
      <el-radio-group v-model="status">
        <el-radio v-for="item in statusOptions" :key="item.label" :label="item.value">{{
          item.label
        }}</el-radio>
      </el-radio-group>
    </div> -->

    <div class="table-wrap">
      <el-table :data="tableData" border v-loading="loading" height="100%">
        <el-table-column
          min-width="150"
          show-overflow-tooltip
          prop="sn"
          :label="$t('deviceList.sn')"
        />
        <el-table-column
          show-overflow-tooltip
          prop="modelName"
          :label="$t('deviceList.deviceType')"
        />
        <el-table-column
          show-overflow-tooltip
          min-width="200"
          prop="groupName"
          :label="$t('deviceList.deviceGroup')"
        />/>
        <el-table-column :label="$t('operation')" width="130">
          <template slot-scope="{ row }">
            <div class="operateRow">
              <el-button type="text" @click="toDetail(row)">{{
                $t('details')
              }}</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <pagination
      slot="pagination"
      :total="total"
      :page-size.sync="params.pageSize"
      :page.sync="params.currentPage"
      @change="getData"
    />
    <detail-modal
      :current-item="currentItem"
      :faterItem="faterItem"
      :visible.sync="detailVisible"
      v-if="detailVisible"
    />
  </page>
</template>

<script>
import watchParamsGetData from '@/mixins/watchParamsGetData'
import DetailModal from './DetailModal'

export default {
  props: ['operateType', 'faterItem'],
  mixins: [watchParamsGetData],
  data() {
    return {
      status: 1,
      detailVisible: false,
      firstIn: true,
      currentItem: null,
      tableData: []
    }
  },
  created() {},
  computed: {
    statusOptions() {
      const statusOptions1 = [
        {
          label: this.$t('operateList.pushSuccee'),
          value: 1
        },
        {
          label: this.$t('operateList.downloaded'),
          value: 2
        },
        {
          label: this.$t('operateList.alreadyInstalled'),
          value: 3
        }
      ]

      const statusOptions2 = [
        {
          label: this.$t('operateList.pushSuccee'),
          value: 1
        },
        {
          label: this.$t('operateList.noApp'),
          value: 6
        },
        {
          label: this.$t('operateList.uninstallComplete'),
          value: 5
        }
      ]

      return this.operateType === 2 ? statusOptions2 : statusOptions1
    }
  },
  components: {
    DetailModal
  },
  watch: {
    status(val) {
      this.getData()
    }
  },
  methods: {
    async getData(loading = true) {
      loading ? (this.loading = true) : ''
      try {
        this.params.publishId = this.faterItem.publishId
        this.params.status = this.status
        const res = await this.$api.appManagement.listFromDevice(this.params)

        if (res && res.result) {
          this.tableData = res.result.list
          this.total = res.result.total
        } else {
          this.tableData = []
          this.total = 0
        }
      } catch (err) {
        console.log(err)
      } finally {
        this.loading = false
      }
    },

    toDetail(row) {
      this.detailVisible = true
      this.currentItem = row
    }
  }
}
</script>

<style scoped lang="scss">
img {
  width: 60px;
  height: 60px;
}
.table-wrap {
  min-height: 230px;
  .row {
    display: flex;

    flex-wrap: wrap;
    img {
      margin-right: 10px;
    }
    & > div {
      border: 1px solid #ccc;
      margin: 5px;
      padding: 5px;
      display: flex;
      align-items: center;
      flex-direction: column;
      .name {
        font-size: 15px;
        font-weight: bold;
        margin: 10px 0;
      }

      & + div {
        margin-left: 10px;
      }
    }
  }
}
</style>
