<template>
  <page>
    <div class="stepHeader">
      <el-steps :active="active" align-center finish-status="success">
        <el-step :title="$t('publishApp.selectApp')" />
        <el-step :title="$t('publishApp.selectDevice')" />
        <el-step :title="$t('publishApp.complete')" />
      </el-steps>
    </div>

    <el-form :model="form" :rules="rules" label-width="150px" v-loading="loading">
      <el-form-item
        v-show="active === 0"
        :label="$t('publishApp.forceUpdates')"
        prop="forcedUpdateType"
      >
        <el-radio-group v-model="form.forcedUpdateType">
          <el-radio :label="1">{{ $t('yes') }}</el-radio>
          <el-radio :label="0">{{ $t('no') }}</el-radio>
        </el-radio-group>
        <el-tooltip
          class="item"
          :content="$t('publishApp.updatesTips')"
          placement="right"
          effect="light"
        >
          <i class="el-icon-question " style="margin-left:20px;font-size:16px;"></i>
        </el-tooltip>
      </el-form-item>
      <el-form-item v-show="active === 0" :label="$t('publishApp.selectApps')" prop="appInfos">
        <div class="row viewer" v-viewer>
          <div class="select" @click="selectAppVisible = true">
            <i class="el-icon-plus avatar-uploader-icon"></i>
            <span>{{ $t('publishApp.selectListApps') }}</span>
          </div>
          <div class="info" v-for="(item, index) in form.appInfos" :key="item.id + item.appName">
            <i class="el-icon-close" @click="delHandler(index, item.appId)"></i>
            <img :src="item.iconPathUrl" />
            <div class="name">{{ item.appName }}</div>
            <div class="info-b">
              <span>{{ item.appSize }}</span>
              <span>{{ $t('deviceList.versionNo') }} V{{ item.versionName }}</span>
            </div>
            <div>
              <span>{{ $t('appList.applicableModel') }} {{ item.applicableModelStr || '-' }}</span>
            </div>
          </div>
        </div>
      </el-form-item>
      <el-form-item v-show="active === 1" :label="$t('publishApp.publishingMethod')">
        <el-select
          v-model="form.publishWay"
          :placeholder="$t('publishApp.selectPublishingMethod')"
          @change="publishWayChange"
        >
          <el-option
            v-for="item in types"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
        <div class="group" v-if="form.publishWay">
          <div class="g-header">
            <el-button type="primary" v-if="form.publishWay === 2" @click="snVisible = true">{{
              $t('publishApp.selectDevice')
            }}</el-button>
            <el-button
              type="primary"
              v-if="form.publishWay === 3"
              @click="deviceTypeVisivle = true"
              >{{ $t('deviceModal.selectDeviceModel') }}</el-button
            >
            <el-button type="primary" v-if="form.publishWay === 5" @click="serviceVisible = true">{{
              $t('deviceModal.selectCustomer')
            }}</el-button>
            <span class="label" v-if="form.publishWay === 6"
              >{{ $t('publishApp.selected') }}：{{ $t('publishApp.fullRelease') }}
              {{ deviceTotal || 0 }}{{ $t('adminHomePage.tower')
              }}{{ $t('deviceModal.devices') }}</span
            >

            <div class="r" v-if="form.publishWay && form.publishWay !== 6">
              <div class="info">
                <span class="label"
                  >{{ $t('publishApp.selected') }}：{{ total }}{{ $t('adminHomePage.tower')
                  }}{{ $t('deviceModal.devices') }}</span
                >
              </div>
              <el-link
                type="danger"
                :underline="false"
                icon="el-icon-delete"
                @click="selectData = []"
                >{{ $t('publishApp.clearAll') }}</el-link
              >
            </div>
          </div>
          <div class="content" :class="!form.publishWay || form.publishWay === 6 ? 'hide' : ''">
            <template v-if="form.publishWay === 2">
              <p class="no-data" v-if="!selectData.length">{{ $t('publishApp.noDevice') }}</p>
              <template v-else>
                <div class="item sn" v-for="(item, index) in selectData" :key="item.sn">
                  <div class="item-left">
                    {{ item.sn }}
                  </div>
                  <div class="item-right" @click="selectData.splice(index, 1)">
                    x
                  </div>
                </div>
              </template>
            </template>
            <template v-if="form.publishWay === 3">
              <p class="no-data" v-if="!selectData.length">{{ $t('publishApp.noDeviceType') }}</p>
              <template v-else>
                <div
                  class="item device-type"
                  v-for="(item, index) in selectData"
                  :key="item.id + item.name"
                >
                  <div class="item-left" v-viewer>
                    <img :src="item.imagePath" />
                    <div>
                      <span class="name">{{ item.name }}</span>
                      <span class="num"
                        >{{ $t('publishApp.common') }} {{ item.deviceTotal || 0 }}
                        {{ $t('adminHomePage.tower') }}{{ $t('deviceModal.devices') }}</span
                      >
                    </div>
                  </div>
                  <div class="item-right" @click="selectData.splice(index, 1)">
                    x
                  </div>
                </div>
              </template>
            </template>
            <template v-if="form.publishWay === 5">
              <p class="no-data" v-if="!selectData.length">
                {{ $t('publishApp.noGroupSelection') }}
              </p>
              <template v-else>
                <div class="item" v-for="(item, index) in selectData" :key="item.id + index">
                  <div class="item-left">
                    <span class="name">{{ item.name }}</span>
                    <span class="num"
                      >{{ $t('publishApp.common') }} {{ item.deviceTotal || 0 }}
                      {{ $t('adminHomePage.tower') }}{{ $t('deviceModal.devices') }}</span
                    >
                  </div>
                  <div class="item-right" @click="selectData.splice(index, 1)">
                    x
                  </div>
                </div>
              </template>
            </template>
          </div>
        </div>
      </el-form-item>

      <el-form-item v-show="active === 2" :label="$t('publishApp.appsPublish')">
        <div class="row viewer" v-viewer>
          <div class="info" v-for="item in bapingList" :key="item.id + item.appName">
            <img :src="item.iconPathUrl" />

            <div class="name">{{ item.appName }}</div>
            <div class="info-b">
              <span>{{ item.appSize }}</span>
              <span>{{ $t('deviceList.versionNo') }} V{{ item.versionName }}</span>
            </div>
            <div>
              <span>{{ $t('appList.applicableModel') }} {{ item.applicableModelStr || '-' }}</span>
            </div>
          </div>
        </div>
      </el-form-item>

      <el-form-item v-show="active === 2" :label="$t('publishApp.publishingMethod')">
        <span style="margin-right:10px">{{ currentWay }}</span>
        <span
          >{{ $t('publishApp.common') }}
          {{ form.publishWay === 6 ? deviceTotal || 0 : total || 0 }} {{ $t('adminHomePage.tower')
          }}{{ $t('deviceModal.devices') }}</span
        >
      </el-form-item>
    </el-form>

    <div class="footer">
      <el-button v-if="active !== 0 && active !== 3" type="primary" @click="pre">{{
        $t('publishApp.preStep')
      }}</el-button>
      <el-button
        v-if="active <= 2"
        type="primary"
        :disabled="
          (active === 0 && !form.appInfos.length) ||
            (active === 1 && !selectData.length && form.publishWay !== 6)
        "
        @click="next"
        >{{ active === 2 ? $t('publishApp.publishNow') : $t('publishApp.nextStep') }}</el-button
      >

      <el-button v-if="active === 3" @click="active = 0">{{ $t('publishApp.complete') }}</el-button>
    </div>

    <service-modal
      v-if="serviceVisible"
      :visible.sync="serviceVisible"
      :select-data.sync="selectData"
      :appIds="appIds"
    />

    <select-app-modal
      v-if="selectAppVisible"
      :visible.sync="selectAppVisible"
      :operate="$t('publishApp.release')"
      @selectApp="renderData"
    />

    <sn-modal v-if="snVisible" :visible.sync="snVisible" :select-data.sync="selectData" />
    <device-type
      v-if="deviceTypeVisivle"
      :visible.sync="deviceTypeVisivle"
      :select-data.sync="selectData"
    />
  </page>
</template>

<script>
import ServiceModal from '../components/ServiceModal'
import SelectAppModal from '../components/SelectAppModal'
import SnModal from '../components/SnModal'
import DeviceType from '../components/DeviceType'

export default {
  components: { ServiceModal, SnModal, DeviceType, SelectAppModal },
  props: {},
  data() {
    const types = [
      {
        value: 5,
        label: this.$t('publishApp.byCustomer')
      },
      {
        value: 2,
        label: this.$t('publishApp.publishBySN')
      },
      {
        value: 3,
        label: this.$t('publishApp.publishDevice')
      },
      {
        value: 6,
        label: this.$t('publishApp.fullRelease')
      }
    ]
    const rules = {
      appInfos: [{ required: true, message: this.$t('publishApp.selectApps') }],
      type: [{ required: true, message: this.$t('publishApp.selectPublishingMethod') }]
    }
    return {
      active: 0,
      currentWay: '',
      bapingIsSelected: false,
      form: {
        appInfos: [],
        publishObjectIds: [],
        publishWay: '',
        forcedUpdateType: 0
      },
      types,
      rules,
      selectData: [],
      serviceVisible: false,
      selectAppVisible: false,
      snVisible: false,
      deviceTypeVisivle: false,
      loading: false,
      deviceTotal: 0
    }
  },
  computed: {
    currentIds() {
      return this.form.appInfos.map(_ => _.id)
    },
    appIds() {
      console.log(this.form.appInfos)

      return this.form.appInfos.map(_ => _.appId)
    },
    total() {
      if (this.form.publishWay === 2) {
        return this.selectData.length && this.selectData[0].deviceTotal
      } else {
        return this.selectData.reduce((a, b) => {
          return a + (b.deviceTotal || 0)
        }, 0)
      }
    },
    bapingList() {
      const list = []

      this.form.appInfos.forEach(item => {
        list.push(item)
      })

      return list
    }
  },
  created() {},
  watch: {},
  methods: {
    renderData(val) {
      val.forEach(item => {
        this.form.appInfos = [item]
      })
    },
    pre() {
      if (this.active-- < 0) this.active = 0
    },
    async next() {
      if (this.active === 2) {
        try {
          await this.submit()
        } catch (e) {
          console.log(e)
        }
      } else {
        this.active++
      }
    },
    delHandler(index, appId) {
      this.form.appInfos.splice(index, 1)
    },
    publishWayChange(val) {
      this.selectData = []

      this.currentWay = this.types.find(_ => _.value === val).label

      if (val === 6 && !this.deviceTotal) {
        this.deviceTotalHandler()
      }
    },
    async deviceTotalHandler() {
      try {
        this.loading = true

        const res = await this.$api.appManagement.spList({ appIds: this.appIds.join(',') })

        this.selectData = res.result.map(item => {
          return {
            name: item.spName,
            id: item.spId,
            deviceTotal: item.deviceNum
          }
        })

        this.deviceTotal = res.result.reduce((a, b) => {
          return a + (b.deviceNum || 0)
        }, 0)
      } catch (err) {
      } finally {
        this.loading = false
      }
    },
    async submit() {
      if (!this.selectData.length && this.form.publishWay !== 6) {
        return this.$message.warning(this.$t('publishApp.publishingMethodContent'))
      }
      if (this.form.publishWay == 4 && this.deviceTotal >= 3000) {
        return this.$message.warning(this.$t('publishApp.fullReleaseTips'))
      }

      this.form.publishObjectIds = this.selectData.map(item => item.sn || item.id).join(',')

      // console.log(this.form, 'ss')
      // return
      try {
        this.loading = true
        const res = await this.$api.appManagement.publishPost(this.form)
        this.$message({
          message: this.$t('publishApp.publishSuccess'),
          type: 'success',
          duration: 5000
        })

        this.active = 0
        this.currentWay = ''
        this.form.appInfos = []
        this.form.publishObjectIds = []
        this.form.publishWay = ''
        this.selectData = []
        this.form.forcedUpdateType = 0
      } catch (err) {
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.page-com {
  overflow: auto;
}
.stepHeader {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
.el-steps {
  width: 80%;
  ::v-deep .el-step__title {
    font-size: 12px;
  }
}

.container-wrap {
  .baping-active {
    color: #fff !important;
    background-color: #074d29 !important;
    border-color: #074d29 !important;
  }

  .baping-tag {
    color: #fff;
    background-color: #074d29;
    width: 34px;
    height: 40px;
    position: absolute;
    top: 0;
    line-height: 20px;
    left: 12px;
    text-align: center;
    font-size: 12px;
    padding: 1px;
    border-radius: 0 0 2px 2px;
  }

  .row {
    display: flex;
    flex-wrap: wrap;

    img {
      width: 50px;
      height: 50px;
    }

    .info {
      line-height: normal;
      width: 200px;
      flex-wrap: wrap;
      word-break: break-word;
      border: 1px solid #ccc;
      padding: 10px;
      display: flex;
      align-items: center;
      flex-direction: column;
      margin-right: 10px;
      position: relative;
      justify-content: space-between;

      .el-icon-close {
        color: #f56c6c;
        position: absolute;
        right: 0;
        top: 0;
        font-size: 25px;
        cursor: pointer;
      }

      .bottom {
        border-top: 1px solid #ccc;
        padding: 10px 0;
        margin: 0;
        width: 100%;
        display: flex;
        justify-content: center;
        font-size: 25px;

        .el-button:hover {
          color: #074d29;
          background: #f0f9eb;
          border-color: #c2e7b0;
        }

        .el-button:focus {
          color: #606266;
          border-color: #dcdfe6;
          background-color: transparent;
        }
      }
      .name {
        font-size: 15px;
        font-weight: bold;
        margin: 10px 0;
        text-align: center;
        word-break: break-word;
      }
      .info-b {
        padding: 0 10px;
        text-align: center;
        span {
          font-size: 13px;
        }
        span + span {
          margin-left: 10px;
        }
      }
    }
    .select {
      width: 100%;
      margin-bottom: 10px;
      height: 175px;
      display: flex;
      cursor: pointer;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      border: 1px dashed #d9d9d9;
      &:hover {
        border-color: #074d29;
      }
      .el-icon-plus {
        font-size: 80px;
        color: #ccc;
      }
      span {
        font-size: 12px;
      }
    }
  }
  .footer {
    text-align: center;
    margin-bottom: 10px;
  }
  .group {
    .g-header {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;
      align-items: center;
      .r {
        display: flex;
        .info {
          margin-right: 10px;
        }
      }
    }
    .content {
      min-height: 200px;
      overflow: auto;
      border: 1px solid #ccc;
      border-radius: 4px;
      margin-top: 10px;
      &.hide {
        display: none;
      }
    }
    .content {
      position: relative;
      padding: 10px;
      .item {
        display: inline-block;
        overflow: hidden;
        padding: 8px;
        justify-content: space-between;
        border: 1px solid #ccc;
        line-height: 20px;
        align-items: center;
        width: 150px;
        height: 120px;
        position: relative;
        margin-right: 10px;
        .item-left {
          display: flex;
          float: left;
          flex-direction: column;
          .name {
            word-break: break-word;
            font-size: 14px;
          }
          .num {
            font-size: 12px;
            color: #666;
          }
        }
        .item-right {
          font-size: 20px;
          float: right;
          margin-top: 10px;
          cursor: pointer;
          width: 0;
          height: 0;
          border-top: 20px solid #f56c6c;
          border-left: 20px solid transparent;
          position: absolute;
          right: 0;
          margin: 0;
          top: 0;
          &::after {
            content: 'x';
            position: absolute;
            left: -10px;
            font-size: 12px;
            color: #fff;
            top: -25px;
          }
        }
      }

      .sn {
        width: 250px;
        height: 20px;
      }

      .device-type {
        .item-left {
          flex-direction: row;

          img {
            width: 46px;
            max-height: 120px;
            cursor: zoom-in;
          }
        }
        .svg-icon {
          width: 25px;
          height: 25px;
        }

        .item-left > div {
          margin-left: 10px;
          span {
            display: block;
          }
        }
      }
    }
  }
}
</style>
