<template>
  <el-dialog
    v-loading="loading"
    v-elDragDialog
    width="500px"
    :title="$t('deviceList.setTimeZone')"
    :close-on-click-modal="false"
    :visible.sync="show"
    class="add-device"
  >
    <el-form
      style="display:flex;justify-content:center;"
      :model="form"
      size="small"
      ref="form"
      label-width="100px"
    >
      <el-select v-model="form.timeZone" :placeholder="$t('deviceModal.selectArea')">
        <el-option
          v-for="item in timeZoneList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="submitForm(form)">{{ $t('save') }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'
import { timeZone } from '@/lib/timeZone.js'
import { timeZoneEn } from '@/lib/timeZone-en.js'
export default {
  mixins: [dialogCommonParams],
  props: {
    groupIds: {
      type: Array,
      default: () => []
    },
    multipleSelection: {
      type: Array,
      default: () => []
    }
  },
  components: {},
  data() {
    return {
      form: {
        timeZone: ''
      }
    }
  },
  created() {
    if (this.$i18n.locale == 'en') {
      this.timeZoneList = timeZoneEn
    } else {
      this.timeZoneList = timeZone
    }
  },
  methods: {
    async submitForm(data) {
      if (!this.form.timeZone) {
        this.$message.warning(this.$t('deviceModal.pleaseSelectTimeZone'))
        return
      }

      this.loading = true

      const params = {
        parameter1: this.form.timeZone,
        type: 6
      }

      if (this.groupIds.length) {
        params.groupIds = this.groupIds.join(',')
      } else if (this.multipleSelection.length) {
        params.deviceSn = this.multipleSelection.join(',')
      } else {
        params.deviceSn = this.currentItem.sn
      }

      try {
        const res = await this.$api.deviceManagement.publishRemoteAssistance(params)

        this.$message.success(this.$t('deviceModal.settingSuccess'))
        this.show = false
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
