<template>
  <el-dialog
    v-loading="loading"
    v-elDragDialog
    width="500px"
    :title="$t('deviceList.screenBrightnessSetting')"
    :close-on-click-modal="false"
    :visible.sync="show"
  >
    <div class="sliderWrap">
      <el-slider :marks="tempMark" v-model="light" />
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="submitForm()">{{ $t('save') }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'
export default {
  mixins: [dialogCommonParams],
  props: {
    groupIds: {
      type: Array,
      default: () => []
    },
    multipleSelection: {
      type: Array,
      default: () => []
    }
  },
  components: {},
  data() {
    return {
      light: 50,
      tempMark: {
        0: '0',
        100: '100'
      }
    }
  },
  created() {},
  methods: {
    async submitForm() {
      this.loading = true

      const params = {
        parameter1: this.light,
        type: 10
      }

      if (this.groupIds.length) {
        params.groupIds = this.groupIds.join(',')
      } else if (this.multipleSelection.length) {
        params.deviceSn = this.multipleSelection.join(',')
      } else {
        params.deviceSn = this.currentItem.sn
      }

      try {
        const res = await this.$api.deviceManagement.publishRemoteAssistance(params)

        this.$message.success(this.$t('deviceModal.settingSuccess'))
        this.show = false
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .sliderWrap {
  width: 80%;
  margin: 0 auto;
  .el-slider__marks-text {
    width: 40px;
  }

  .el-slider__bar {
    background-image: linear-gradient(to right, #c495ba, #f56c6c);
  }

  .el-slider__button {
    border: 2px solid #f56c6c;
  }

  .el-slider__runway.disabled .el-slider__bar {
    background-image: none;
  }

  .el-slider__runway.disabled .el-slider__button {
    border: 2px solid #c0c4cc;
  }

  .el-slider__stop {
    height: 0;
  }
}
</style>
