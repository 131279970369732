<template>
  <el-dialog
    v-loading="loading"
    v-elDragDialog
    width="500px"
    :title="$t('deviceList.fontSizeSettings')"
    :close-on-click-modal="false"
    :visible.sync="show"
  >
    <el-form
      style="display:flex;justify-content:center;"
      :model="form"
      size="small"
      ref="form"
      label-width="100px"
    >
      <el-radio-group v-model="form.font">
        <el-radio :label="1">{{ $t('deviceModal.small') }}</el-radio>
        <el-radio :label="2">{{ $t('deviceModal.default') }}</el-radio>
        <el-radio :label="3">{{ $t('deviceModal.large') }}</el-radio>
        <el-radio :label="4">{{ $t('deviceModal.maximum') }}</el-radio>
      </el-radio-group>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="submitForm(form)">{{ $t('save') }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'
export default {
  mixins: [dialogCommonParams],
  props: {
    groupIds: {
      type: Array,
      default: () => []
    },
    multipleSelection: {
      type: Array,
      default: () => []
    }
  },
  components: {},
  data() {
    return {
      form: {
        font: 2
      }
    }
  },
  created() {},
  methods: {
    async submitForm(data) {
      this.loading = true

      const params = {
        parameter1: this.form.font,
        type: 11
      }

      if (this.groupIds.length) {
        params.groupIds = this.groupIds.join(',')
      } else if (this.multipleSelection.length) {
        params.deviceSn = this.multipleSelection.join(',')
      } else {
        params.deviceSn = this.currentItem.sn
      }

      try {
        const res = await this.$api.deviceManagement.publishRemoteAssistance(params)

        this.$message.success(this.$t('deviceModal.settingSuccess'))
        this.show = false
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
