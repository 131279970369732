<template>
  <el-dialog
    ref="remoteModal"
    v-loading="loading"
    :title="$t('deviceList.remote')"
    :close-on-click-modal="false"
    :visible.sync="show"
    class="remoteModal"
  >
    <iframe ref="iframe" :src="frameUrl" frameborder="0" width="100%" height="100%"></iframe>

    <div class="bottomWrap">
      <div class="infoWrap">
        <span><b>{{$t('deviceList.CPUUtilization')}}</b>: {{ cpuUsage }}</span>
        <span><b>{{$t('deviceList.cpuTemperature')}}</b>: {{ temperature }}</span>
        <span><b>{{$t('deviceList.memory')}}</b>: {{ memory }}</span>
        <span><b>{{$t('deviceList.disk')}}</b>: {{ storage }}</span>
      </div>
      <div class="btnWrap">
        <el-button
          size="mini"
          v-for="item in commandList"
          :key="item.value"
          @click="$emit('command', item.value)"
          type="primary"
          >{{ item.label }}</el-button
        >
      </div>
    </div>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'
export default {
  mixins: [dialogCommonParams],
  props: ['frameUrl', 'btnShow'],
  components: {},
  data() {
    return {
      commandList: [
      {
          label: this.$t('deviceList.shutdown'),
          value: 8
        },
        {
          label:this.$t('deviceList.restart'),
          value: 4
        },
        {
          label: this.$t('deviceList.closeVirtualKeys'),
          value: 2
        },
        {
          label:this.$t('deviceList.openVirtualKeys'),
          value: 3
        },
        {
          label:this.$t('deviceList.closeWifi'),
          value: 1
        },
        {
          label: this.$t('deviceList.setTimeZone'),
          value: 6
        },
        {
          label: this.$t('deviceList.setSleep'),
          value: 7
        },
        {
          label: this.$t('deviceList.reset'),
          value: 5
        },
        {
          label: this.$t('deviceList.filesTransfer'),
          value: 9
        }
      ],
      cpuUsage: '',
      temperature: '',
      memory: '',
      storage: ''
    }
  },

  created() {
    this.getDetailData()
  },
  methods: {
    async getDetailData() {
      try {
        const res = await this.$api.deviceManagement.deviceGet({sn: this.currentItem.sn})

        const data = res.result
        let available = this.$t('deviceList.available')
        let z = this.$t('deviceList.z')
        if (data.statusRecords && data.statusRecords.length) {
          const targetRecord = data.statusRecords[0]
          this.cpuUsage = targetRecord.cpuUsage || ''
          this.temperature = targetRecord.cpuTemperature || ''
          this.memory = targetRecord.memoryUsed + available+'/' + targetRecord.totalMemory + z
          this.storage = targetRecord.storageUsed + available+'/' + targetRecord.totalStorage + z
        }
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>

<style lang="scss">
.remoteModal {
  overflow: hidden;
  .el-dialog {
    margin-top: 0 !important;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;

    .el-icon-full-screen {
      font-size: 25px;
      cursor: pointer;
      position: absolute;
      top: 10px;
      left: 100px;
    }

    .el-dialog__header {
      padding: 10px;
      height: 20px;
      line-height: 20px;
    }
    .el-dialog__headerbtn {
      top: 12px;
      right: 10px;

      font-weight: bold;
      .el-dialog__close {
        color: #000;
        font-size: 24px;
      }
    }
    .el-dialog__body {
      flex: 1;
      padding: 0px !important;
      display: flex;
      flex-direction: column;

      .bottomWrap {
        padding: 10px;
        display: flex;
        justify-content: space-between;

        .el-button {
          margin-bottom: 6px !important;
        }

        .infoWrap {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          span {
            font-size: 12px;
            margin-right: 16px;

            @media screen and (min-width: 1920px) {
              font-size: 14px;
              margin-right: 20px;
            }
          }
        }
      }
    }
  }
}
</style>
