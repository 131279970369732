<template>
  <page>
    <div slot="pageTop">
      <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal">
        <el-menu-item
          @click="renderData(item.id)"
          v-for="item in menus"
          :index="item.id"
          :key="item.id"
          >{{ item.name }}</el-menu-item
        >
      </el-menu>
    </div>
    <div slot="headerLeft">
      <el-form inline>
        <el-form-item :label="$t('deviceList.sn')">
          <el-input
            :placeholder="$t('deviceList.sn')"
            v-model.trim="params.sn"
            @keyup.enter.native="replaceData"
            clearable
          />
        </el-form-item>
        <el-form-item :label="$t('operateList.pushStatus')">
          <el-select clearable v-model="params.status" filterable :placeholder="$t('placeholder')">
            <el-option
              v-for="item in statusOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-button type="primary" @click="replaceData" plain>{{ $t('query') }}</el-button>
      </el-form>
    </div>
    <div slot="headerRight">
      <el-link @click="download" type="primary">{{
        $t('operateList.downloadDeviceList1')
      }}</el-link>
    </div>

    <div class="table-wrap">
      <el-table :data="tableData" border v-loading="loading" height="100%">
        <el-table-column
          min-width="150"
          show-overflow-tooltip
          prop="sn"
          :label="$t('deviceList.sn')"
        />
        <el-table-column
          show-overflow-tooltip
          prop="modelName"
          :label="$t('deviceList.deviceType')"
        />
        <el-table-column
          show-overflow-tooltip
          min-width="200"
          prop="groupName"
          :label="$t('deviceList.deviceGroup')"
        />
        <el-table-column
          show-overflow-tooltip
          prop="statusName"
          :label="$t('operateList.pushStatus')"
        />
        <el-table-column show-overflow-tooltip prop="remark" :label="$t('deviceType.remark')" />
        <!-- <el-table-column label="操作" width="130">
          <template slot-scope="{ row }">
            <div class="operateRow">
              <el-button type="primary" size="small" @click="toDetail(row)">详情</el-button>
            </div>
          </template>
        </el-table-column> -->
      </el-table>
    </div>
    <pagination
      slot="pagination"
      :total="total"
      :page-size.sync="params.pageSize"
      :page.sync="params.currentPage"
      @change="getData"
    />
    <detail-modal
      :current-item="currentItem"
      :faterItem="faterItem"
      :visible.sync="detailVisible"
      v-if="detailVisible"
    />
  </page>
</template>

<script>
import watchParamsGetData from '@/mixins/watchParamsGetData'
import DetailModal from './DetailModal'

export default {
  mixins: [watchParamsGetData],
  props: ['operateType', 'faterItem'],
  data() {
    return {
      params: {
        appId: '',
        status: ''
      },
      activeIndex: '0',
      detailVisible: false,
      firstIn: true,
      currentItem: null,
      modelList: [],
      tableData: [],
      statusList: {
        0: this.$t('operateList.notPushed'),
        1: this.$t('operateList.pushSuccee'),
        2: this.$t('operateList.downloaded'),
        3: this.$t('operateList.alreadyInstalled'),
        4: this.$t('operateList.run'),
        5: this.$t('operateList.uninstalled'),
        6: this.$t('operateList.noApp'),
        8: this.$t('operateList.alreadyPushed'),
        9: this.$t('operateList.pushFailed'),
        10: this.$t('operateList.installFail')
      }
    }
  },
  components: {
    DetailModal
  },
  created() {
    this.params.appId = (this.menus && this.menus[0]?.id) || ''
    this.activeIndex = (this.faterItem.appInfos && this.faterItem.appInfos[0].appId + '') || '0'
  },
  computed: {
    statusOptions() {
      const statusOptions1 = [
        {
          label: this.$t('operateList.pushSuccee'),
          value: 1
        },
        {
          label: this.$t('operateList.downloaded'),
          value: 2
        },
        {
          label: this.$t('operateList.alreadyInstalled'),
          value: 3
        },
        {
          label: this.$t('operateList.pushFailed'),
          value: 9
        }
      ]

      const statusOptions2 = [
        {
          label: this.$t('operateList.pushSuccee'),
          value: 1
        },
        {
          label: this.$t('operateList.noApp'),
          value: 6
        },
        {
          label: this.$t('operateList.uninstallComplete'),
          value: 5
        },
        {
          label: this.$t('operateList.pushFailed'),
          value: 9
        }
      ]

      return this.operateType === 2 ? statusOptions2 : statusOptions1
    },
    menus() {
      let arr = []

      if (this.faterItem && this.faterItem.appInfos) {
        arr = this.faterItem.appInfos.map(item => ({
          id: item.appId + '',
          name: item.appName
        }))
      }
      return arr
    }
  },
  methods: {
    renderData(id) {
      this.params.appId = id
      this.getData()
    },
    async download() {
      const loading = this.$loading()
      try {
        const params = { ...this.params }
        if (!params.status) delete params.status
        params.pageSize = -1
        const res = await this.$api.appManagement.downloadListByStatus(params)

        this.$utils.downFile(res)
      } catch (e) {
        console.log(e)
      } finally {
        setTimeout(() => {
          loading.close()
        }, 1000)
      }
    },
    async getData() {
      this.loading = true

      try {
        const params = this.params

        params.publishId = this.faterItem.publishId
        if (!params.status) delete params.status

        const res = await this.$api.appManagement.listFromApp(params)

        if (res && res.result) {
          this.tableData = (res.result.list || []).map(item => {
            item.statusName = this.statusList[item.status]
            return item
          })
          this.total = res.result.total
        } else {
          this.tableData = []
          this.total = 0
        }
      } catch (err) {
        console.log(err)
      } finally {
        this.loading = false
      }
    },

    toDetail(row) {
      this.detailVisible = true
      this.currentItem = row
    }
  }
}
</script>

<style scoped lang="scss">
.el-menu {
  display: flex;
  justify-content: center;
  margin: 0 10px 10px;
}
.table-wrap {
  min-height: 230px;
  .row {
    display: flex;

    flex-wrap: wrap;
    img {
      margin-right: 10px;
    }
    & > div {
      border: 1px solid #ccc;
      margin: 5px;
      padding: 5px;
      display: flex;
      align-items: center;
      flex-direction: column;
      .name {
        font-size: 15px;
        font-weight: bold;
        margin: 10px 0;
      }

      & + div {
        margin-left: 10px;
      }
    }
  }
}
</style>
