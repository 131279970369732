<template>
  <el-dialog
    v-loading="loading"
    v-elDragDialog
    width="800px"
    :title="$t('groupManagement.addDeviceToGroup')"
    :append-to-body="true"
    :close-on-click-modal="false"
    :visible.sync="show"
    class="device-to-group"
  >
    <el-form :model="form" :rules="rules" size="small" ref="form" label-width="100px">
      <el-form-item :label="$t('groupManagement.groupName')">
        <span>{{ currentItem.name || '' }}</span>
      </el-form-item>
      <el-form-item :label="$t('groupManagement.addMethod')">
        <el-radio-group @change="addWayChange" v-model="form.addWay">
          <el-radio :label="1">{{ $t('groupManagement.singleAdd') }}</el-radio>
          <el-radio :label="2">{{ $t('groupManagement.batchAdd') }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item v-if="form.addWay === 2" label="">
        <a @click="downLoad" href="javascript:void(0)" style="color:#074d29">{{
          $t('groupManagement.batchAddGroupTemplate')
        }}</a>
        <p>{{ $t('groupManagement.addGroupTips') }}</p>
      </el-form-item>
      <el-form-item
        v-if="form.addWay === 1"
        :label="$t('deviceList.sn')"
        :prop="form.addWay === 1 ? 'sn' : ''"
      >
        <el-input style="width:275px" type="text" v-model="form.sn" />
      </el-form-item>

      <el-form-item
        v-if="form.addWay === 2"
        required
        :label="$t('uploadFiles')"
        :prop="form.addWay === 2 ? 'file' : ''"
      >
        <el-upload
          drag
          :on-change="onchange"
          :show-file-list="true"
          :multiple="false"
          :auto-upload="false"
          accept=".csv,.xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          ref="upload"
          name="file"
          action="null"
        >
          <div @click="$refs.upload.clearFiles()">
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
              {{ $t('deviceModal.dropFilesHere') }}，{{ $t('deviceModal.or') }}
              <em>{{ $t('deviceModal.clickUpload') }}</em>
            </div>
          </div>
        </el-upload>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="submitForm(form)">{{ $t('save') }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'
export default {
  mixins: [dialogCommonParams],
  components: {},
  data() {
    return {
      loading: false,
      name: '',
      form: {
        addWay: 1,
        sn: '',
        file: ''
      },
      rules: {
        sn: [{ required: true, message: this.$t('deviceModal.pleaseEnterSNNumber') }],
        file: [{ required: true, message: this.$t('deviceModal.pleaseUploadFile') }]
      }
    }
  },

  created() {},

  methods: {
    async downLoad() {
      this.loading = true
      try {
        const res = await this.$api.deviceManagement.deviceDownload({ type: 0 })

        window.open(res.result, '_self')
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    onchange(file) {
      this.form.file = file.raw
    },
    addWayChange(val) {
      this.$refs.form.clearValidate()
      //   this.form.addWay = val
    },
    submitForm(data) {
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.loading = true

          try {
            // await this.$api.deviceManagement.addDeviceToGroup(data)
            // this.$message.success(this.isCreated ? '新增成功！' : '编辑成功！')
            this.show = false
          } catch (e) {
            console.log(e)
          } finally {
            this.loading = false
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped></style>
