<template>
  <el-dialog
    v-loading="loading"
    v-elDragDialog
    width="500px"
    :title="isCreated ? $t('groupManagement.addGroup') : $t('groupManagement.editGroup')"
    :close-on-click-modal="false"
    :visible.sync="show"
    class="add-group"
  >
    <el-form :model="form" :rules="rules" size="small" ref="form" label-width="100px">
      <el-form-item :label="$t('groupManagement.groupName')" prop="name">
        <el-input v-model.trim="form.name" />
      </el-form-item>
      <el-form-item :label="$t('groupManagement.groupInfo')">
        <el-input type="textarea" resize="none" rows="4" v-model="form.description" />
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="submitForm(form)">{{ $t('save') }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'
export default {
  mixins: [dialogCommonParams],
  components: {},
  data() {
    return {
      loading: false,
      timeZoneList: [],
      form: {
        name: '',
        description: ''
      },
      rules: {
        name: [{ required: true, message: this.$t('customerHome.enterGroupName') }]
      }
    }
  },

  created() {
    if (!this.isCreated) {
      Object.keys(this.form).forEach(key => {
        this.form[key] = this.currentItem[key]
      })
    }
  },

  methods: {
    submitForm(data) {
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.loading = true

          if (!this.isCreated) {
            data.id = this.currentItem.id
          }

          try {
            if (this.isCreated) {
              // 新增的默认添加至 全部分组
              data.parentId = 200
              data.level = 2
              data.serinalNo = 1
            }
            this.isCreated
              ? await this.$api.deviceManagement.groupAdd(data)
              : await this.$api.deviceManagement.groupUpdate(data)
            this.$message.success(this.isCreated ? this.$t('newSuccess') : this.$t('editSuccess'))
            this.$emit('getData')
            this.show = false
          } catch (e) {
            console.log(e)
          } finally {
            this.loading = false
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped></style>
