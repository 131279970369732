<template>
  <el-dialog
    v-elDragDialog
    width="800px"
    heigh
    :title="$t('groupManagement.manageDevices')"
    :close-on-click-modal="false"
    :visible.sync="show"
    class="manage-device"
  >
    <div class="device-header">
      <div class="flex">
        <p style="margin-right:30px;">{{ $t('groupManagement.groupName') }}：{{ name }}</p>
        <p>{{ $t('groupManagement.curDeviceNumber') }}: {{ total }}</p>
      </div>

      <!-- <el-button type="primary"  @click="addDeviceToGroup">添加设备至分组</el-button> -->
    </div>

    <el-table size="small" :data="tableData" border v-loading="loading" :height="400">
      <el-table-column show-overflow-tooltip="" prop="sn" :label="$t('deviceList.sn')" />
      <el-table-column prop="modelName" :label="$t('deviceList.deviceType')" />
      <el-table-column prop="firmwareVersion" :label="$t('deviceList.firmwareVersion')" />
      <el-table-column :label="$t('operation')">
        <template slot-scope="{ row }">
          <el-button type="text" @click="editGroup(row)">{{
            $t('groupManagement.modifyGroup')
          }}</el-button>
        </template>
      </el-table-column>
    </el-table>

    <pagination
      slot="pagination"
      :total="total"
      :page-size.sync="params.pageSize"
      :page.sync="params.currentPage"
      @change="getData"
    />

    <device-to-groupModal :currentItem="currentItem" :visible.sync="visible2" v-if="visible2" />

    <edit-group-modal
      @getData="renderData(true)"
      :currentRow="currentRow"
      :groupList="groupList"
      :visible.sync="visible3"
      v-if="visible3"
    />
  </el-dialog>
</template>

<script>
import watchParamsGetData from '@/mixins/watchParamsGetData'
import dialogCommonParams from '@/mixins/dialogCommonParams'
import DeviceToGroupModal from './DeviceToGroupModal'
import EditGroupModal from './EditGroupModal'
export default {
  mixins: [dialogCommonParams, watchParamsGetData],
  components: {
    DeviceToGroupModal,
    EditGroupModal
  },
  data() {
    return {
      name: '',
      total: 0,
      visible2: false,
      visible3: false,
      loading: false,
      firstIn: true,
      currentRow: null,
      tableData: [],
      modelList: [],
      groupList: []
    }
  },

  created() {
    this.getGroupList()
    this.name = this.currentItem.name
  },

  methods: {
    addDeviceToGroup() {
      this.visible2 = true
    },
    editGroup(row) {
      this.currentRow = row
      this.visible3 = true
    },
    async getGroupList() {
      const res = await this.$api.deviceManagement.groupList({ pageSize: -1 })

      this.groupList = ((res.result && res.result.list) || []).map(item => {
        return {
          label: item.name,
          value: item.id
        }
      })
    },
    async getModelList() {
      let res = await this.$api.deviceManagement.modelList({ pageSize: -1 })

      this.modelList = ((res.result && res.result.list) || []).map(item => {
        return {
          label: item.name,
          value: item.id
        }
      })
    },
    async getData(loading = true) {
      loading ? (this.loading = true) : ''
      if (this.firstIn) {
        try {
          await this.getModelList()
        } catch (e) {
          console.log(e)
        } finally {
          this.firstIn = false
          this.renderData()
        }
      } else {
        this.renderData()
      }
    },
    async renderData(updateData) {
      if (updateData) {
        this.$emit('getData')
      }

      try {
        const data = this.params
        data.groupId = this.currentItem.id
        const res = await this.$api.deviceManagement.deviceList(data)

        this.tableData = ((res.result && res.result.list) || []).map(item => {
          const targetModel = this.modelList.find(_ => _.value === item.model)

          item.modelName = (targetModel && targetModel.label) || ''

          return item
        })
        this.total = res.result?.total || 0
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async removeHandler(row) {
      this.$confirm(this.$t('groupManagement.removeDevice'), this.$t('tips'), {
        confirmButtonText: this.$t('confirm'),
        cancelButtonText: this.$t('cancel')
      })
        .then(async () => {
          this.loading = true

          try {
            const res = await this.$api.deviceManagement.groupReomve({
              sn: row.sn,
              groupId: row.groups[0].id
            })
            this.$message.success(this.$t('groupManagement.removeSuccess'))
            this.renderData()
          } catch (e) {
            console.log(e)
          } finally {
            this.loading = false
          }
        })
        .catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped>
.manage-device {
  .el-table .el-button {
    padding: 5px 6px;
  }

  .device-header {
    align-items: center;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
  }
}
</style>
