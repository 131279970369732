<template>
  <el-dialog
    v-elDragDialog
    width="500px"
    :title="$t('deviceList.filesTransfer')"
    :close-on-click-modal="false"
    :visible.sync="show"
    :before-close="close"
    class="add-device"
  >
    <el-form
      v-loading="loading"
      :element-loading-text="uploadMessage"
      :model="form"
      size="small"
      ref="form"
      label-width="120px"
    >
      <el-form-item
        :rules="[{ required: true, message: $t('deviceModal.pleaseUploadFile') }]"
        :label="$t('uploadFiles')"
        prop="file"
      >
        <el-upload
          action="null"
          :on-change="file => onFilechange(file, 'file')"
          :on-remove="() => (form.file = '')"
          :show-file-list="true"
          :multiple="false"
          :auto-upload="false"
          ref="fileUpload"
        >
          <el-button @click="$refs.fileUpload.clearFiles()" type="primary">{{
            $t('deviceModal.selectFile')
          }}</el-button>
        </el-upload>
      </el-form-item>

      <p class="tips" v-loading="tipLoading">{{ uploadTip }}</p>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button @click="close">{{ $t('cancel') }}</el-button>
      <el-button :disabled="loading === true" type="primary" @click="submitForm(form)">{{
        $t('upload')
      }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'
import axios from 'axios'
import { PLATFORM } from '@/lib/constant'
export default {
  mixins: [dialogCommonParams],
  props: {
    groupIds: {
      type: Array,
      default: () => []
    },
    multipleSelection: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      loading: false,
      uploadMessage: 0,
      form: {
        file: ''
      },
      tipLoading: false,
      maxSize: 300
    }
  },
  computed: {
    uploadTip() {
      const maxSize = this.maxSize
      let tip = this.$t('deviceModal.fileDescription')
      if (this.currentItem.systemType === 2) {
        tip = tip.replace('/Download/', 'C:\\Donwload')
      }
      tip = tip.replace('300M', maxSize + 'M')
      return tip
    }
  },
  created() {
    this.getTransferFilesMaxSize()
  },
  methods: {
    async getTransferFilesMaxSize() {
      try {
        this.tipLoading = true
        const res = await this.$api.deviceManagement.deviceGetTransferFilesMaxSize()
        this.maxSize = Number(res.result || 300)
      } catch (error) {
        console.log(error)
      } finally {
        this.tipLoading = false
      }
    },
    close() {
      this.show = false
      this.uploadMessage = 0
      if (this.cancels) {
        this.cancels()
      }
    },

    onFilechange(file, key) {
      const limit = file.size / 1024 / 1024 < this.maxSize

      if (!limit) {
        this.$message({
          message: this.$t('deviceModal.fileSize').replace('300MB', this.maxSize + 'MB'),
          type: 'warning'
        })
        this.$refs.fileUpload.clearFiles()
        return
      }

      this.form[key] = file.raw
    },

    submitForm(data) {
      const CancelToken = axios.CancelToken
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.loading = true

          const formData = new FormData()

          for (const i in this.form) {
            const formItemV = this.form[i]
            if (formItemV) {
              formData.append(i, formItemV)
              if (i === 'file') {
                formData.append('parameter1', formItemV.name)
              }
            }
          }

          if (this.groupIds.length) {
            formData.append('groupIds', this.groupIds.join(','))
          } else if (this.multipleSelection.length) {
            formData.append('deviceSn', this.multipleSelection.join(','))
          } else {
            formData.append('deviceSn', this.currentItem.sn)
          }

          try {
            const apiType = PLATFORM === 'admin' ? '/admin/api' : '/sp/api'

            let url = `/scpserver${apiType}/publish/fileTransfer`

            if (process.env.NODE_ENV === 'development') {
              url = url.replace('/scpserver', '')
            }
            let locale = ''
            if (this.$i18n.locale == 'en') {
              locale = 'en_US'
            } else if (this.$i18n.locale == 'ruRU') {
              locale = 'ru_RU'
            } else {
              locale = 'zh_CN'
            }

            const res = await axios.post(url, formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
                system: PLATFORM === 'admin' ? 4 : 5,
                'Accept-Language': locale
              },
              timeout: 20000000,
              cancelToken: new CancelToken(c => {
                this.cancels = c
              }),
              onUploadProgress: progressEvent => {
                if (progressEvent && progressEvent.loaded) {
                  let complete = (((progressEvent.loaded / progressEvent.total) * 100) | 0) + '%'
                  this.uploadMessage = complete
                }
              }
            })

            if (res.data.code !== 'OK') {
              this.$message({
                message: res.data.summary || this.$t('systemError'),
                type: 'error',
                duration: 3 * 1000
              })
            } else {
              this.$message.success(this.$t('deviceModal.transferSuccess'))
              this.show = false
            }
          } catch (e) {
            console.log(e)
            this.$message({
              message: res.data.summary || this.$t('systemError'),
              type: 'error',
              duration: 3 * 1000
            })
          } finally {
            this.loading = false
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-loading-spinner i {
  font-size: 30px;
}

::v-deep .el-loading-spinner .el-loading-text {
  color: #85d71a;
}

.tips {
  font-size: 13px;
  color: #666;
  margin-left: 30px;
  margin-bottom: 20px;
}
</style>
