<template>
  <el-dialog
    v-loading="loading"
    v-elDragDialog
    width="500px"
    :title="$t('deviceModal.sleepSettings')"
    :close-on-click-modal="false"
    :visible.sync="show"
  >
    <el-form
      style="display:flex;justify-content:center;"
      :model="form"
      size="small"
      ref="form"
      label-width="100px"
    >
      <el-select v-model="form.sleep">
        <el-option
          v-for="item in sleepList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="submitForm(form)">{{ $t('save') }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'
export default {
  mixins: [dialogCommonParams],
  props: {
    groupIds: {
      type: Array,
      default: () => []
    },
    multipleSelection: {
      type: Array,
      default: () => []
    }
  },
  components: {},
  data() {
    return {
      form: {
        sleep: 0
      }
    }
  },
  created() {
    this.sleepList = [
      {
        label: this.$t('deviceModal.never'),
        value: 0
      },
      {
        label: '15' + this.$t('deviceModal.second'),
        value: 15
      },
      {
        label: '30' + this.$t('deviceModal.second'),
        value: 30
      },
      {
        label: '1' + this.$t('deviceModal.minute'),
        value: 60
      },
      {
        label: '2' + this.$t('deviceModal.minute'),
        value: 120
      },
      {
        label: '5' + this.$t('deviceModal.minute'),
        value: 300
      },
      {
        label: '10' + this.$t('deviceModal.minute'),
        value: 600
      },
      {
        label: '30' + this.$t('deviceModal.minute'),
        value: 1800
      }
    ]
  },
  methods: {
    async submitForm(data) {
      this.loading = true

      const params = {
        parameter1: this.form.sleep,
        type: 7
      }

      if (this.groupIds.length) {
        params.groupIds = this.groupIds.join(',')
      } else if (this.multipleSelection.length) {
        params.deviceSn = this.multipleSelection.join(',')
      } else {
        params.deviceSn = this.currentItem.sn
      }

      try {
        const res = await this.$api.deviceManagement.publishRemoteAssistance(params)

        this.$message.success(this.$t('deviceModal.settingSuccess'))
        this.show = false
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
